
.slick-track {
    position: relative;
    top: 0;
    left: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    align-items: center;
}

.c-dVipbc {
    display: flex;
    flex-direction: column;
    padding: 5em 1em 0px;
    justify-content: center;
    margin: var(--space-2);
    align-items: center;
}