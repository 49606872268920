.menuBox {
    animation-name: slideIn;
    animation-duration: 1s;
    transform: translateX(0%);
}

@keyframes slideIn {
    from {
      transform: translateX(100%);
    }
    to {
      transform: translateX(0%);
    }
  }
  